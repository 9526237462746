import React from 'react'
import bfm from "../assets/img/hero-bg.svg"
import mission from "../assets/img/mission.svg"
import vision from "../assets/img/vision.svg"

function HomePage() {
    return (
        <div>

            {/* Hero section */}
            <section id="hero" class="d-flex align-items-center">
                <div class="container" >
                    <div class="row">
                        <div class="col-xl-6">
                            <h1> Business Facilitation Market Inc,</h1>
                            <h2>Your growth, our lifestyle</h2>
                            <a href="#about" class="btn-get-started scrollto">Get Started</a>
                        </div>
                    </div>
                </div>

            </section>

            {/* client section */}
            {/* <section id="clients" class="clients">
                <div class="container" data-aos="zoom-in">

                    <div class="clients-slider swiper">
                        <div class="swiper-wrapper align-items-center">
                            <div class="swiper-slide"><img src="../assets/img/clients/client-1.png" class="img-fluid" alt="" /></div>
                            <div class="swiper-slide"><img src="../assets/img/clients/client-2.png" class="img-fluid" alt="" /></div>
                            <div class="swiper-slide"><img src="../assets/img/clients/client-3.png" class="img-fluid" alt="" /></div>
                            <div class="swiper-slide"><img src="../assets/img/clients/client-4.png" class="img-fluid" alt="" /></div>
                            <div class="swiper-slide"><img src="../assets/img/clients/client-5.png" class="img-fluid" alt="" /></div>
                            <div class="swiper-slide"><img src="../assets/img/clients/client-6.png" class="img-fluid" alt="" /></div>
                            <div class="swiper-slide"><img src="../assets/img/clients/client-7.png" class="img-fluid" alt="" /></div>
                            <div class="swiper-slide"><img src="../assets/img/clients/client-8.png" class="img-fluid" alt="" /></div>
                        </div>
                        <div class="swiper-pagination"></div>
                    </div>

                </div>
            </section> */}

            {/* service section */}

            <section id="services" class="about section-bg">
                <div class="container" >

                    <div class="row no-gutters">
                        <div class="content col-xl-5 d-flex align-items-stretch">
                            <div class="content">
                                <h3>BFM Inc. SARL is a multi-disciplinary company</h3>
                                <p>
                                    At BFM Inc, we're committed to helping our clients achieve their goals by providing them with the tools they need to succeed in the mining
                                    industry as well as other diverse sectors. We offer a wide range of services including:
                                </p>

                            </div>
                        </div>

                        <div class="col-xl-7 d-flex align-items-stretch">
                            <div class="icon-boxes d-flex flex-column justify-content-center">
                                <div class="row">

                                    <div class="col-md-6 icon-box" >
                                        <i class="bx bx-shield"></i>
                                        <h4>Inventory control and optimization</h4>
                                    </div>

                                    <div class="col-md-6 icon-box" >
                                        <i class="bx bx-shield"></i>
                                        <h4>Developement of tailored tech-based solutions</h4>
                                    </div>
                                    <div class="col-md-6 icon-box" >
                                        <i class="bx bx-cube-alt"></i>
                                        <h4>Sourcing and procurement optimization</h4>
                                    </div>

                                    <div class="col-md-6 icon-box" >
                                        <i class="bx bx-cube-alt"></i>
                                        <h4>B2B networking, business opportunity finding and handovers</h4>
                                    </div>

                                    <div class="col-md-6 icon-box" >
                                        <i class="bx bx-shield"></i>
                                        <h4>Busines evalutation and improvement strategies</h4>
                                    </div>

                                    <div class="col-md-6 icon-box" >
                                        <i class="bx bx-shield"></i>
                                        <h4>Mining supply and procurement alliance (MISPAL)</h4>
                                    </div>

                                    <div class="col-md-6 icon-box" >
                                        <i class="bx bx-cube-alt"></i>
                                        <h4>Professional catologuing, stock counts and inventory audits</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>


            {/* about, vision and service section */}
            <section id="about" class="tabs">
                <div class="container" >

                    <ul class="nav nav-tabs row d-flex">
                        <li class="nav-item col-3">
                            <a class="nav-link active show" data-bs-toggle="tab" data-bs-target="#tab-1">
                                <i class="ri-gps-line"></i>
                                <h4 class="d-none d-lg-block">ABOUT US</h4>
                            </a>
                        </li>
                        <li class="nav-item col-3">
                            <a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab-2">
                                <i class="ri-body-scan-line"></i>
                                <h4 class="d-none d-lg-block">OUR MISSION</h4>
                            </a>
                        </li>
                        <li class="nav-item col-3">
                            <a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab-3">
                                <i class="ri-sun-line"></i>
                                <h4 class="d-none d-lg-block">OUR VISION</h4>
                            </a>
                        </li>

                    </ul>

                    <div class="tab-content">
                        <div class="tab-pane active show" id="tab-1">
                            <div class="row">
                                <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0" >
                                    <h3>BFM Inc. SARL</h3>
                                    <p class="fst-italic">
                                        Founded in 2022, BFM Inc. is a SARL company based in the Democratic Republic of the Congo.
                                        After years of research into mining industry issues and general supply-chain
                                        challenges,
                                        the company has established itself as a solution-finding and
                                        facilitation entity that provides innovative ways of conducting business in the DRC.



                                    </p>
                                    <ul>
                                        <li><i class="ri-check-double-line"></i>  BFM Inc. SARL is a multi-disciplinary company founded with the mother-goal, facilitating business transactions for the growth of our clients a n
                                            d business partners. Through market research, flexibility, determination and innovation, we have identified potential lucrative opportunities a n d brought t h e m to life by liaising with our collaborators from the supply chain and tech-dev sectors. We believe in creating a collaborative environment that allows us to work together in ways that
                                            benefit each other.</li>
                                        <li><i class="ri-check-double-line"></i>                                      From inventory optimization to procurement facilitation and sale of products such as tailored tech-based solutions for the Mining industry, we can also provide you with spare parts, safety equipment and general consumables.
                                            Our team is a mixture of young and senior individuals, combining ambition, determination, strong work ethic and experience. The dream team ofsuccess.◊</li>

                                    </ul>

                                </div>
                                <div class="col-lg-6 order-1 order-lg-2 text-center" >
                                    <img src={bfm} alt="" class="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="tab-2">
                            <div class="row">
                                <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                                    <h3>At BFM Inc. SARL,</h3>
                                    <p>
                                        we believe that strong and reliable business relationships are the foundation of success.
                                    </p>

                                    <ul>
                                        <li><i class="ri-check-double-line"></i> We cherish our
                                            customers, partners, and employees who help us grow and succeed. Our goal is to establish and maintain an active collaborationbetweenourpartnersandtheircustomerswhile
                                            ensuring growth is pursued, obtained and maintained! We build our business relationships for the next
                                            generation to come.</li>
                                    </ul>
                                </div>
                                <div class="col-lg-6 order-1 order-lg-2 text-center">
                                    <img src={mission} alt="" class="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="tab-3">
                            <div class="row">
                                <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                                    <h3>Business Facilitation Market Inc,</h3>
                                    <p>
                                        Build the most reliable business alliance in the central African region, and by the year 2028, become a multimillion-dollar structure capable of boosting and accompanying local and international businesses towards
                                        achieving their goals.
                                    </p>
                                </div>
                                <div class="col-lg-6 order-1 order-lg-2 text-center">
                                    <img src={vision} alt="" class="img-fluid" />
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>


            {/* product section */}

            <section id="product" class="about section-bg">
                <div class="container">

                    <div class="row no-gutters">

                        <div class="col-xl-7 d-flex align-items-stretch">
                            <div class="icon-boxes d-flex flex-column justify-content-center">

                                <div class="row">
                                    <div class="col-md-6 icon-box" >
                                        <i class="bx bx-receipt"></i>
                                        <h4>PICCO</h4>
                                        <p>Purchasing, Inventory and Cataloguing Companion</p>
                                    </div>
                                    <div class="col-md-6 icon-box">
                                        <i class="bx bx-cube-alt"></i>
                                        <h4>FARMLY</h4>
                                        <p>General farm stock management system</p>
                                    </div>
                                    <div class="col-md-6 icon-box" >
                                        <i class="bx bx-images"></i>
                                        <h4>LIMERAC</h4>
                                        <p>Ligue des Mercenaires Académiques</p>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="content col-xl-5 d-flex align-items-stretch">
                            <div class="content">
                                <h3>Our products</h3>
                                <p>
                                    As a company that sells spare parts for industrial machinery, we sell
                                    products such as filters, mechanical seals, valves, and other products of different brands for industrial use.
                                </p>

                                <p>
                                    As a global provider of general consumables, we offer a wide range of solutions to meet your needs, including TI equipment, office
                                    consumables, culinary consumables, personal protective equipment, etc.
                                </p>

                                <p>
                                    We don't do our job just because we're paid to do so. Our success depends on yours, making your growth our lifestyle!
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            {/* contact section */}

            <section id="contact" class="contact">
                <div class="container" >

                    <div class="section-title">
                        <h2>Contact</h2>
                    </div>

                    <div class="row">

                        <div class="col-lg-6">

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="info-box">
                                        <i class="bx bx-map"></i>
                                        <h3>Our Address</h3>
                                        <p>2B, Amisi croisement Lac Kipopo 2, Kalubwe, Lubumbashi, RDC</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="info-box mt-4">
                                        <i class="bx bx-envelope"></i>
                                        <h3>Email Us</h3>
                                        <p>info@bfmincorp.com<br />bfmrdc@gmail.com</p>
                                        
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="info-box mt-4">
                                        <i class="bx bx-phone-call"></i>
                                        <h3>Call Us</h3>
                                        <p>+243 900 078 283
                                            <br />+243 821 024 925<br /> +260 764 604 414</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-6">
                            <form action="forms/contact.php" method="post" role="form" class="php-email-form">
                                <div class="row">
                                    <div class="col form-group">
                                        <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" required />
                                    </div>
                                    <div class="col form-group">
                                        <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" required />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" required />
                                </div>
                                <div class="form-group">
                                    <textarea class="form-control" name="message" rows="5" placeholder="Message" required></textarea>
                                </div>
                                <div class="my-3">
                                    <div class="loading">Loading</div>
                                    <div class="error-message"></div>
                                    <div class="sent-message">Your message has been sent. Thank you!</div>
                                </div>
                                <div class="text-center"><button type="submit">Send Message</button></div>
                            </form>
                        </div>

                    </div>

                </div>
            </section>

        </div>
    )
}

export default HomePage
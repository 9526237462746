import React from 'react'

function Footer() {
    return (
        <footer id="footer">

            <div class="footer-top">
                <div class="container">
                    <div class="row">

                        <div class="col-lg-4 col-md-6 footer-contact">
                            <h3>Business Facilitation Market Inc<span>.</span></h3>
                            <p>

                                2B, Amisi croisement Lac Kipopo 2, Kalubwe<br /> Lubumbashi<br />RDC<br />

                                <strong>Phone:</strong> +243 900 078 283<br />
                                <strong>Email:</strong> info@bfmincorp.com<br />
                            </p>
                        </div>

                        <div class="col-lg-4 col-md-6 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><i class="bx bx-chevron-right"></i> <a href="#hero">Home</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="#about">About us</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="#services">Services</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="#product">Our products</a></li>
                            </ul>
                        </div>

                       

                        <div class="col-lg-4 col-md-6 footer-newsletter">
                            <h4>Join Our Newsletter</h4>
                            <form action="" method="post">
                                <input type="email" name="email" /><input type="submit" value="Subscribe" />
                            </form>
                        </div>

                    </div>
                </div>
            </div>

            {/* <div class="container d-md-flex py-4">

                                    <div class="me-md-auto text-center text-md-start">
                                        <div class="copyright">
                                            &copy; Copyright <strong><span>Presento</span></strong>. All Rights Reserved
                                        </div>
                                        <div class="credits">

                                            Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
                                        </div>
                                    </div>
                                    <div class="social-links text-center text-md-end pt-3 pt-md-0">
                                        <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
                                        <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
                                        <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
                                        <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
                                        <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
                                    </div>
                                </div> */}
        </footer>
    )
}

export default Footer
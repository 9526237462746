import React from 'react'
import logo from "../assets/img/logo.svg"

function Header() {
    return (
        <header id="header" class="fixed-top d-flex align-items-center">
            <div class="container d-flex align-items-center">
                {/* <h1 class="logo me-auto"><a href="index.html">Presento<span>.</span></a></h1> */}

                <a href="index.html" class="logo me-auto"><img src={logo} alt="" /></a>

                <nav id="navbar" class="navbar order-last order-lg-0">
                    <ul>
                        <li><a class="nav-link scrollto active" href="#hero">Home</a></li>
                        <li><a class="nav-link scrollto" href="#about">About</a></li>
                        <li><a class="nav-link scrollto" href="#services">Services</a></li>
                        <li><a class="nav-link scrollto " href="#product">Product</a></li>
                        {/* <li><a class="nav-link scrollto" href="#team">Team</a></li> */}
                        <li><a class="nav-link scrollto" href="#contact">Contact</a></li>
                    </ul>
                    <i class="bi bi-list mobile-nav-toggle"></i>
                </nav>

                <a href="#about" class="get-started-btn scrollto">Get Started</a>
            </div>
        </header>
    )
}

export default Header
import './App.css';
import "./assets/vendor/aos/aos.css" 
import "./assets/vendor/bootstrap/css/bootstrap.min.css" 
import "./assets/vendor/bootstrap-icons/bootstrap-icons.css" 
import "./assets/vendor/boxicons/css/boxicons.min.css" 
import "./assets/vendor/glightbox/css/glightbox.min.css" 
import "./assets/vendor/remixicon/remixicon.css" 
import "./assets/vendor/swiper/swiper-bundle.min.css" 
// import "./assets/vendor/purecounter/purecounter_vanilla.js" 
// import "./assets/vendor/aos/aos.js" 
// import "./assets/vendor/bootstrap/js/bootstrap.bundle.min.js" 
// import "./assets/vendor/glightbox/js/glightbox.min.js" 
// import "./assets/vendor/isotope-layout/isotope.pkgd.min.js"
// import "./assets/vendor/swiper/swiper-bundle.min.js" 
// import "./assets/vendor/php-email-form/validate.js" 
// import "./assets/js/main.js" 
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';



import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import HomePage from './pages/HomePage';
import Header from './components/Header';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path='/' element={<HomePage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
